import React from 'react';
import { Button, ButtonProps, Image } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { useAuthStore } from '../AppContextProvider';
import googleLogo from './google-logo.svg';

interface GoogleSignInButtonProps extends ButtonProps {
  onSignIn?: () => void;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({
  onSignIn,
  ...props
}) => {
  const authStore = useAuthStore();

  const signInWithGoogle = async () => {
    await authStore.signInWithGoogle();
    if (onSignIn) {
      onSignIn();
    }
  };

  if (authStore.isSignedIn) {
    return null;
  }

  return (
    <Button
      variant="outline"
      colorScheme="gray"
      color="gray.500"
      onClick={signInWithGoogle}
      height="auto"
      fontFamily="sans-serif"
      py={3}
      pl={3}
      pr={4}
      {...props}
    >
      <Image src={googleLogo} boxSize={6} mr={4} /> Sign in with Google
    </Button>
  );
};

export default observer(GoogleSignInButton);
