import React from 'react';
import { Box, Button, ButtonProps, ButtonGroup, Image } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import useHandleResponse from './useHandleResponse';
import { selectedButtonVariant } from '../helpers';

const gifs: Record<string, string> = {
  Yes: 'https://media.giphy.com/media/H6Qqxi3RsbyuCry8Ma/giphy.gif',
  No: 'https://media.giphy.com/media/FYCxLHCIGaioo/giphy.gif',
};

const StyledButton: React.FC<ButtonProps> = (props) => (
  <Button
    type="button"
    colorScheme="blue"
    fontSize="3xl"
    width="150px"
    height="auto"
    {...props}
  />
);

const YesNo: React.FC = () => {
  const { response, handleResponse } = useHandleResponse();
  const value = response && response.data.value;

  return (
    <Box textAlign="center">
      <ButtonGroup
        display="inline-flex"
        spacing={8}
        alignItems="stretch"
        height="64px"
        mx="auto"
        my={8}
      >
        <StyledButton
          variant={selectedButtonVariant(value, 'Yes')}
          onClick={handleResponse('Yes')}
        >
          Yes
        </StyledButton>
        <StyledButton
          variant={selectedButtonVariant(value, 'No')}
          onClick={handleResponse('No')}
        >
          No
        </StyledButton>
      </ButtonGroup>

      {value && (
        <Box textAlign="center" mt={8}>
          <Image src={gifs[value]} alt={value} mx="auto" ignoreFallback />
        </Box>
      )}
    </Box>
  );
};

export default observer(YesNo);
