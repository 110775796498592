import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { Question } from '../store/question';
import useCurrentRoom from './useCurrentRoom';
import ParticipantBreakdown from './results/ParticipantBreakdown';

interface HostQuestionProps {
  question: Question;
}

const HostQuestion: React.FC<HostQuestionProps> = ({ question }) => {
  const room = useCurrentRoom();

  const isCurrent = room.currentQuestion?.id === question.id;
  return (
    <Box>
      Hello HostQuestion - {question.id} {question.type}
      <Button onClick={() => question.delete()}>Delete</Button>
      {!isCurrent && (
        <Button onClick={() => room.setCurrentQuestion(question)}>
          Set Current
        </Button>
      )}
      {isCurrent && (
        <Button onClick={() => room.setCurrentQuestion()}>Clear Current</Button>
      )}
      <Flex mt={4}>
        <ParticipantBreakdown question={question} />
      </Flex>
    </Box>
  );
};

export default observer(HostQuestion);
