import { GlobalStyles } from '@chakra-ui/theme-tools';

const styles: GlobalStyles = {
  global: {
    body: {
      fontFamily: 'body',
      color: 'gray.900',
    },
    '*, *::before, *::after': {
      borderColor: 'gray.300',
    },
  },
};

export default styles;
