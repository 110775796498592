import { extendTheme } from '@chakra-ui/core';
import colors from './foundations/colors';
import typography from './foundations/typography';
import styles from './styles';
import Button from './components/button';

const customChakraTheme = extendTheme({
  colors,
  ...typography,
  styles,
  components: {
    Button,
  },
});

export type CustomTheme = typeof customChakraTheme;

export default customChakraTheme;
