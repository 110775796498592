import React, { useRef } from 'react';
import { Box, Heading } from '@chakra-ui/core';
import random from 'lodash/random';

const titles = ["I'd love to know what you think."];

interface QuestionIntroProps {
  subtitle?: string;
}

const QuestionIntro: React.FC<QuestionIntroProps> = ({
  subtitle = 'What say you?',
}) => {
  const countRef = useRef(random(0, titles.length - 1));
  const title = titles[countRef.current];

  return (
    <Box textAlign="center">
      <Heading size="lg" fontWeight="semibold">
        {title}
      </Heading>
      <Heading size="2xl" fontWeight="black" textTransform="uppercase">
        {subtitle}
      </Heading>
    </Box>
  );
};

export default QuestionIntro;
