import React from 'react';
import { Center, Container, Heading, List, ListItem } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useRoomStore } from '../AppContextProvider';
import { Room } from '../store/room';
import routes from '../routes';

const ListRooms: React.FC = () => {
  const roomStore = useRoomStore();
  const rooms = roomStore.rooms.map((room: Room) => {
    return (
      <ListItem key={room.id}>
        <Link to={routes.host(room.id)}>{room.name}</Link>
      </ListItem>
    );
  });
  if (roomStore.creating) {
    return null;
  }

  return (
    <Container my={16}>
      <Center>
        <Heading as="h2" size="xl">
          My rooms
        </Heading>
      </Center>
      <List>{rooms}</List>
    </Container>
  );
};

export default observer(ListRooms);
