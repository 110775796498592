import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Center } from '@chakra-ui/core';
import CreateRoomForm, { CreateRoomFormValues } from './CreateRoomForm';
import routes from '../routes';
import { useAuthStore, useRoomStore } from '../AppContextProvider';
import Header from './Header';
import ListRooms from './ListRooms';
import GoogleSignInButton from '../account/GoogleSignInButton';

const CreateRoom: React.FC = () => {
  const history = useHistory();
  const store = useRoomStore();
  const authStore = useAuthStore();
  const handleSubmit = async (form: CreateRoomFormValues) => {
    const owner = authStore.currentUser?.uid;
    if (!owner) {
      throw new Error('You need to be logged in to create a room');
    }
    const roomDocument = await store.addRoom({ ...form, owner });
    history.push(routes.host(roomDocument.id));
  };

  if (authStore.isSignedIn === null) {
    return null; // We don't know the state of the login yet
  }
  return (
    <>
      <Header />
      {!authStore.isSignedIn && (
        <Center my={16}>
          <GoogleSignInButton />
        </Center>
      )}
      {authStore.isSignedIn && <CreateRoomForm onSubmit={handleSubmit} />}

      {authStore.isSignedIn && <ListRooms />}
    </>
  );
};

export default observer(CreateRoom);
