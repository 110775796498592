import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Fade,
  SlideFade,
  Button,
  FormControl,
  Input,
} from '@chakra-ui/core';
import { Controller } from 'react-hook-form';
import useNamePromptForm from './useNamePromptForm';

interface ChangeNameModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangeNameModal: React.FC<ChangeNameModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { form, onSubmit } = useNamePromptForm(onClose);
  const { isSubmitting } = form.formState;
  const initialRef = React.useRef(null);

  return (
    <Fade timeout={500} in={isOpen}>
      {(overlayStyles) => (
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          onClose={onClose}
          returnFocusOnClose={false}
        >
          <ModalOverlay style={overlayStyles}>
            <SlideFade timeout={250} in={isOpen} unmountOnExit={false}>
              {(contentStyles) => (
                <ModalContent style={contentStyles}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <ModalHeader>Change your name</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                      <FormControl>
                        <Controller
                          name="name"
                          control={form.control}
                          as={<Input ref={initialRef} />}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter mt={3}>
                      <Button
                        colorScheme="gray"
                        variant="ghost"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isLoading={isSubmitting}
                        ml={3}
                        px={6}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </form>
                </ModalContent>
              )}
            </SlideFade>
          </ModalOverlay>
        </Modal>
      )}
    </Fade>
  );
};

export default ChangeNameModal;
