import bind from 'bind-decorator';
import { firestore } from 'firebase';
import { Collection, Document, getFirebase, getFirestore } from 'firestorter';
import { computed, observable, when } from 'mobx';
import {
  Question,
  QuestionData,
  QuestionType,
  QUESTION_COLLECTION,
} from './question';

export const ROOM_COLLECTION = 'rooms';

interface RoomData {
  name: string;
  currentQuestionId?: string;
  owner: string;
}

export class Room extends Document<RoomData> {
  private _questions: Collection<Question> | undefined;

  private _batch?: firestore.WriteBatch;

  get questions(): Collection<Question> | undefined {
    if (!this.path || !this.data.owner) {
      return undefined;
    }

    if (!this._questions) {
      this._questions = new Collection(
        () => `${this.path}/${QUESTION_COLLECTION}`,
        {
          createDocument: (s, o) => new Question(s, o),
          query: (ref) => ref.where('owner', '==', this.data.owner),
        }
      );
    }
    return this._questions;
  }

  get name(): string {
    return this.data.name;
  }

  set name(name: string) {
    this.update({ name });
  }

  @computed
  get currentQuestion(): Question | undefined {
    if (this.data.currentQuestionId) {
      return Question.fromId(this.path, this.data.currentQuestionId);
    }
    return undefined;
  }

  @bind
  async setCurrentQuestion(question?: Question): Promise<void> {
    this.update({
      currentQuestionId:
        question?.id || getFirebase().firestore.FieldValue.delete(),
    });
  }

  @bind
  async addQuestion(type: QuestionType): Promise<Question> {
    if (!this.questions) {
      throw new Error('Room has not been loaded first');
    }
    const data: QuestionData = {
      type,
      owner: this.data.owner,
      created: new Date(),
    };
    return this.questions.add(data);
  }

  @bind
  async delete(): Promise<void> {
    if (!this.questions) {
      throw new Error('Questions are not loaded yet');
    }

    await when(() => !!this.questions && this.questions.isLoaded);

    const toRemove: firestore.DocumentReference[] = [];

    // Collect all refs from each response and question
    this.questions.docs.forEach(async (question) => {
      if (question.ref) {
        toRemove.push(question.ref);
      }
      await when(() => !!question.responses && question.responses.isLoaded);
      question.responses.docs.forEach((r) => {
        if (r.ref) {
          toRemove.push(r.ref);
        }
      });
    });

    // Remove them all in a single batch
    this._batch = getFirestore().batch();
    toRemove.forEach((ref) => this._batch?.delete(ref));
    if (this.ref) {
      this._batch.delete(this.ref);
    }
    return this._batch.commit();
  }
}

export type RoomCollection = Collection<Room>;

export class RoomStore {
  private roomCollection = new Collection<Room>(ROOM_COLLECTION, {
    createDocument: (source, options) => new Room(source, options),
  });

  @observable private _uid?: string;

  @observable creating = false;

  private currentRoom: Room | undefined;

  private currentRoomId?: string;

  async addRoom(room: RoomData): Promise<Room> {
    this.creating = true;
    const newRoom = await this.roomCollection.add(room);
    this.creating = false;
    return newRoom;
  }

  setCurrentRoom(id: string): Room {
    if (!this.currentRoom || this.currentRoomId !== id) {
      this.currentRoom = new Room(`${ROOM_COLLECTION}/${id}`);
      this.currentRoomId = id;
    }
    return this.currentRoom;
  }

  set uid(uid: string | undefined) {
    this._uid = uid;
    if (this._uid) {
      this.roomCollection.query = (ref) => ref.where('owner', '==', this._uid);
    }
  }

  get rooms(): Room[] {
    if (!this._uid) {
      return [];
    }
    return this.roomCollection.docs;
  }
}
