export interface ColorHues {
  50?: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const gray: ColorHues = {
  50: 'hsl(204, 45%, 98%)',
  100: 'hsl(210, 38%, 95%)',
  200: 'hsl(214, 32%, 91%)',
  300: 'hsl(211, 25%, 84%)',
  400: 'hsl(214, 20%, 69%)',
  500: 'hsl(216, 15%, 52%)',
  600: 'hsl(218, 17%, 35%)',
  700: 'hsl(218, 23%, 23%)',
  800: 'hsl(220, 26%, 14%)',
  900: 'hsl(230, 21%, 11%)',
};

const purple: ColorHues = {
  100: 'hsl(293, 14%, 89%)',
  200: 'hsl(292, 14%, 78%)',
  300: 'hsl(295, 14%, 67%)',
  400: 'hsl(294, 14%, 56%)',
  500: 'hsl(294, 16%, 45%)',
  600: 'hsl(294, 16%, 36%)',
  700: 'hsl(295, 17%, 27%)',
  800: 'hsl(294, 16%, 23%)',
  900: 'hsl(292, 16%, 18%)',
};

const ice: ColorHues = {
  100: 'hsl(198, 56%, 96%)',
  200: 'hsl(201, 58%, 90%)',
  300: 'hsl(202, 57%, 83%)',
  400: 'hsl(201, 56%, 76%)',
  500: 'hsl(201, 57%, 65%)',
  600: 'hsl(201, 43%, 59%)',
  700: 'hsl(201, 30%, 46%)',
  800: 'hsl(201, 30%, 39%)',
  900: 'hsl(202, 31%, 26%)',
};

const blue: ColorHues = {
  100: 'hsl(217, 48%, 88%)',
  200: 'hsl(217, 48%, 76%)',
  300: 'hsl(217, 47%, 64%)',
  400: 'hsl(217, 47%, 52%)',
  500: 'hsl(217, 71%, 40%)',
  600: 'hsl(217, 72%, 32%)',
  700: 'hsl(217, 72%, 28%)',
  800: 'hsl(217, 72%, 24%)',
  900: 'hsl(218, 71%, 20%)',
};

const orange: ColorHues = {
  100: 'hsl(17, 89%, 93%)',
  200: 'hsl(16, 91%, 83%)',
  300: 'hsl(16, 90%, 75%)',
  400: 'hsl(16, 90%, 68%)',
  500: 'hsl(16, 90%, 65%)',
  600: 'hsl(16, 53%, 52%)',
  700: 'hsl(17, 49%, 39%)',
  800: 'hsl(16, 49%, 32%)',
  900: 'hsl(16, 49%, 19%)',
};

const teal: ColorHues = {
  100: 'hsl(185, 18%, 86%)',
  200: 'hsl(187, 18%, 72%)',
  300: 'hsl(186, 18%, 57%)',
  400: 'hsl(187, 23%, 44%)',
  500: 'hsl(186, 44%, 29%)',
  600: 'hsl(187, 43%, 24%)',
  700: 'hsl(186, 44%, 17%)',
  800: 'hsl(187, 44%, 15%)',
  900: 'hsl(187, 43%, 12%)',
};

const yellow: ColorHues = {
  100: 'hsl(31, 100%, 96%)',
  200: 'hsl(31, 100%, 83%)',
  300: 'hsl(32, 100%, 75%)',
  400: 'hsl(31, 100%, 66%)',
  500: 'hsl(32, 100%, 58%)',
  600: 'hsl(32, 80%, 52%)',
  700: 'hsl(31, 73%, 41%)',
  800: 'hsl(32, 73%, 35%)',
  900: 'hsl(32, 73%, 17%)',
};

const pink: ColorHues = {
  100: 'hsl(12, 94%, 94%)',
  200: 'hsl(11, 95%, 92%)',
  300: 'hsl(11, 96%, 90%)',
  400: 'hsl(11, 97%, 86%)',
  500: 'hsl(11, 96%, 79%)',
  600: 'hsl(11, 63%, 72%)',
  700: 'hsl(11, 43%, 64%)',
  800: 'hsl(12, 25%, 48%)',
  900: 'hsl(12, 25%, 40%)',
};

const red: ColorHues = {
  100: 'hsl(8, 72%, 92%)',
  200: 'hsl(7, 73%, 79%)',
  300: 'hsl(7, 73%, 71%)',
  400: 'hsl(7, 73%, 63%)',
  500: 'hsl(7, 73%, 58%)',
  600: 'hsl(7, 51%, 47%)',
  700: 'hsl(8, 52%, 41%)',
  800: 'hsl(8, 52%, 35%)',
  900: 'hsl(7, 51%, 23%)',
};

const sky: ColorHues = {
  100: 'hsl(240, 14%, 99%)',
  200: 'hsl(220, 14%, 96%)',
  300: 'hsl(228, 14%, 93%)',
  400: 'hsl(230, 12%, 90%)',
  500: 'hsl(227, 13%, 86%)',
  600: 'hsl(223, 4%, 69%)',
  700: 'hsl(230, 3%, 60%)',
  800: 'hsl(225, 2%, 35%)',
  900: 'hsl(180, 2%, 8%)',
};

const aliases: Record<string, ColorHues> = {
  primary: blue,
  status: teal,
  warning: yellow,
  danger: red,
};

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  ...aliases,
  gray,
  blue,
  red,
  sky,
  pink,
  yellow,
  teal,
  orange,
  ice,
  purple,
  // Unset unused Chakra default colors.
  linkedin: null,
  whatsapp: null,
  facebook: null,
  messenger: null,
  telegram: null,
  twitter: null,
  cyan: null,
};

export type Colors = typeof colors;

export default colors;
