import { ResponseData } from '../store/response';

interface TallyResponse {
  [key: string]: number;
}

export function tallyResponses(
  acc: TallyResponse,
  current: string
): TallyResponse {
  const tally = acc;
  if (!tally[current]) {
    tally[current] = 1;
  } else {
    tally[current]++;
  }
  return tally;
}

export function getUniqueAnswers(responseArray: ResponseData[]): string[] {
  return responseArray
    .map(({ value }: { value: string }) => value)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
}
