import React from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  Heading,
  Input,
  Spacer,
  useDisclosure,
  VStack,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CopyUrlButton from './CopyUrlButton';
import { Room } from '../store/room';
import routes from '../routes';

export interface CreateRoomFormValues {
  name: string;
}

interface UpdateRoomFormProps {
  defaultValues?: CreateRoomFormValues;
  room: Room;
  onSubmit: (data: CreateRoomFormValues) => void;
}

const UpdateRoomForm: React.FC<UpdateRoomFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { register, handleSubmit, formState } = useForm<CreateRoomFormValues>({
    defaultValues,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isSubmitting } = formState;
  const { roomId } = useParams<{ roomId: string }>();
  const origin = `${window.location.origin}${routes.participate(roomId)}`;

  const handleClick = (data: CreateRoomFormValues) => {
    onSubmit(data);
    onClose();
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(handleClick)}>
        <VStack spacing={4}>
          {!isOpen ? (
            <Heading as="h1" size="xl" textAlign="center">
              {defaultValues?.name}
            </Heading>
          ) : (
            <FormControl>
              <Input
                textAlign="center"
                defaultValue={defaultValues?.name}
                type="name"
                name="name"
                id="name"
                ref={register}
                disabled={isSubmitting}
                fontSize={36}
                fontWeight="bold"
              />
            </FormControl>
          )}
          <ButtonGroup spacing={0}>
            <Button onClick={onOpen} hidden={isOpen}>
              Edit Room Name
            </Button>
            <Button type="submit" hidden={!isOpen}>
              Save Room Name
            </Button>
            <Spacer w={4} />
            <CopyUrlButton url={origin}>Copy Share Link</CopyUrlButton>
          </ButtonGroup>
        </VStack>
      </form>
    </Container>
  );
};

export default UpdateRoomForm;
