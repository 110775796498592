import React from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
  Container,
} from '@chakra-ui/core';
import { observer } from 'mobx-react';
import useNamePromptForm from './useNamePromptForm';
import GoogleSignInButton from '../account/GoogleSignInButton';

interface NamePromptProps {
  onSetName?: () => void;
}

const NamePrompt: React.FC<NamePromptProps> = ({
  onSetName = () => undefined,
}) => {
  const { form, onSubmit } = useNamePromptForm(onSetName);
  const { isSubmitting } = form.formState;

  return (
    <Box textAlign="center">
      <Container as="form" my={16} onSubmit={form.handleSubmit(onSubmit)}>
        <FormControl>
          <InputGroup size="lg">
            <Input
              placeholder="Enter your name"
              type="name"
              name="name"
              id="name"
              ref={form.register}
              required
            />
            <InputRightAddon px={0} border={0} overflow="hidden">
              <Button
                type="submit"
                size="lg"
                colorScheme="primary"
                borderRadius={0}
                isLoading={isSubmitting}
              >
                Join Room
              </Button>
            </InputRightAddon>
          </InputGroup>
        </FormControl>
      </Container>

      <GoogleSignInButton />
    </Box>
  );
};

export default observer(NamePrompt);
