import React from 'react';
import { Container } from '@chakra-ui/core';
import UpdateRoomForm from './UpdateRoomForm';
import { CreateRoomFormValues } from '../start/CreateRoomForm';
import { Room } from '../store/room';

interface UpdateRoomProps {
  room: Room;
}

const UpdateRoom: React.FC<UpdateRoomProps> = ({ room }) => {
  const handleSubmit = async (form: CreateRoomFormValues) => {
    await room.update({ name: form.name });
  };

  return (
    <Container maxWidth="1280px">
      <UpdateRoomForm
        room={room}
        defaultValues={{ name: room.data.name }}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default UpdateRoom;
