import React from 'react';
import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/core';
import { ResponseData } from '../../store/response';

interface ColumnDataType {
  answer: string;
  responses: ResponseData[];
}

const ParticipantList: React.FC<ColumnDataType> = ({ answer, responses }) => {
  return (
    <Box>
      <Heading as="h3" size="md" color="orange.500" mb={4} textAlign="center">
        {answer}
      </Heading>
      <UnorderedList styleType="none" p={0} spacing={0} ml={0}>
        {responses.map((response: ResponseData) => (
          <ListItem key={response.owner} textAlign="center">
            {response.name}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default ParticipantList;
