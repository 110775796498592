import React from 'react';
import { Box } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import UpdateRoom from '../host/UpdateRoom';
import { Room } from '../store/room';

interface HeaderProps {
  room: Room;
}

const Header: React.FC<HeaderProps> = observer(({ room }) => {
  if (!room.data.name) {
    return null;
  }

  return (
    <Box bg="orange.100" py={8} w="100%" color="orange.500">
      <UpdateRoom room={room} />
    </Box>
  );
});

export default Header;
