import React from 'react';
import { ButtonGroup } from '@chakra-ui/core';

import QuestionButton from './QuestionButton';
import { QuestionType } from '../store/question';

interface AddQuestionProps {
  onSelect: (question: QuestionType) => void;
  value?: QuestionType;
}

const AddQuestion: React.FC<AddQuestionProps> = ({ onSelect, value }) => {
  return (
    <ButtonGroup spacing={4}>
      <QuestionButton
        type={QuestionType.YesNo}
        onSelect={onSelect}
        value={value}
      >
        Yes or No
      </QuestionButton>
      <QuestionButton
        type={QuestionType.Range}
        onSelect={onSelect}
        value={value}
      >
        Range
      </QuestionButton>
    </ButtonGroup>
  );
};

export default AddQuestion;
