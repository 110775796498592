import { useCallback } from 'react';
import { useAuthStore } from '../../AppContextProvider';
import useCurrentRoom from '../../host/useCurrentRoom';
import { Response } from '../../store/response';

export default function useHandleResponse(): {
  response: Response | undefined;
  handleResponse: (result: string) => () => void;
} {
  const room = useCurrentRoom();
  const authStore = useAuthStore();

  const response = room.currentQuestion?.currentResponse(
    authStore.currentUser?.uid || ''
  );

  const handleResponse = useCallback(
    (r: string) => {
      return async () => {
        const question = room.currentQuestion;
        if (!question) {
          throw new Error("I don't have a question");
        }
        const userId = await authStore.getUserId();
        question.addResponse({
          owner: userId,
          value: r,
          name: authStore.name,
        });
      };
    },
    [room, authStore]
  );
  return { response, handleResponse };
}
