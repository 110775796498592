import React, { useContext, useRef } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { initFirestorter } from 'firestorter';
import { autorun } from 'mobx';
import AuthStore from './store/auth';
import { RoomStore } from './store/room';

const context = React.createContext<AppContext | null>(null);

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

initFirestorter({ firebase });

class AppContext {
  authStore = new AuthStore();

  roomStore = new RoomStore();

  constructor() {
    autorun(() => {
      this.roomStore.uid = this.authStore.currentUser?.uid;
    });
  }
}

const AppContextProvider: React.FC = ({ children }) => {
  const contextValue = useRef(new AppContext());
  return (
    <context.Provider value={contextValue.current}>{children}</context.Provider>
  );
};

export function useApplicationContext(): AppContext {
  const appContext = useContext(context);
  if (!appContext) {
    throw new Error(
      'Unable to find application context, did you specify the AppContextProvider?'
    );
  }
  return appContext;
}

export function useRoomStore(): RoomStore {
  return useApplicationContext().roomStore;
}

export function useAuthStore(): AuthStore {
  return useApplicationContext().authStore;
}

export default AppContextProvider;
