import React from 'react';
import {
  Box,
  Button,
  Container,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { useAuthStore } from '../AppContextProvider';
import NamePrompt from './NamePrompt';
import ChangeNameModal from './ChangeNameModal';

const Account: React.FC = () => {
  const authStore = useAuthStore();
  const changeNameDisclosure = useDisclosure();

  if (authStore.name) {
    return (
      <>
        <Box textAlign="center" mb={6} py={4} px={8}>
          <HStack display="inline-flex" spacing={4}>
            <Text>Hi, {authStore.name}!</Text>
            <Button
              variant="link"
              colorScheme="primary"
              fontSize="inherit"
              onClick={changeNameDisclosure.onOpen}
            >
              Change Name
            </Button>
            {authStore.isSignedIn && (
              <Button
                variant="link"
                colorScheme="red"
                fontSize="inherit"
                onClick={authStore.signOut}
              >
                Sign Out
              </Button>
            )}
          </HStack>
        </Box>
        <ChangeNameModal
          isOpen={changeNameDisclosure.isOpen}
          onClose={changeNameDisclosure.onClose}
        />
      </>
    );
  }

  return (
    <Container>
      <NamePrompt />
    </Container>
  );
};

export default observer(Account);
