const Button = {
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: {
    _disabled: {
      opacity: 0.4,
    },
  },
};

export default Button;
