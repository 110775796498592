import { Button, useClipboard, useToast } from '@chakra-ui/core';
import * as React from 'react';

interface CopyUrlButtonProps {
  url: string;
}

const CopyUrlButton: React.FC<CopyUrlButtonProps> = ({ url, children }) => {
  const { hasCopied, onCopy } = useClipboard(url);
  const toast = useToast();

  React.useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'URL copied to clipboard',
        status: 'success',
        isClosable: true,
      });
    }
  }, [hasCopied, toast]);

  return <Button onClick={() => onCopy()}>{children}</Button>;
};

export default CopyUrlButton;
