import { Document } from 'firestorter';

export const RESPONSE_COLLECTION = 'responses';

export interface ResponseData {
  value: string;
  owner: string;
  name?: string;
}

export class Response extends Document<ResponseData> {
  get value(): string {
    return this.data.value;
  }

  set value(v: string) {
    this.update({
      value: v,
    });
  }

  get owner(): string {
    return this.data.owner;
  }

  get name(): string | undefined {
    return this.data.name;
  }
}
