import React from 'react';
import { Box, Center, Heading, Image } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { QuestionType } from '../store/question';
import YesNo from './questions/YesNo';
import Range from './questions/Range';
import QuestionIntro from './QuestionIntro';
import useCurrentRoom from '../host/useCurrentRoom';

const Question: React.FC = () => {
  const room = useCurrentRoom();
  const { currentQuestion } = room;

  const isLoading = currentQuestion?.isLoading;

  if (isLoading) {
    return null;
  }

  switch (currentQuestion?.type) {
    case QuestionType.Range:
      return (
        <>
          <QuestionIntro subtitle="Choose... wisely" />
          <Range />
        </>
      );
    case QuestionType.YesNo:
      return (
        <>
          <QuestionIntro subtitle="What say you?" />
          <YesNo />
        </>
      );
    default:
      return (
        <Box textAlign="center" mt={16}>
          <Heading fontSize="10vw" lineHeight={1}>
            &lt;Crickets/&gt;
          </Heading>
          <Heading fontSize="2vw" fontWeight="normal">
            There are no current questions, how about some kittens instead?
          </Heading>
          <Center mt={6}>
            <Image
              src="https://www.saltwire.com/media/photologue/photos/cache/kittens_XR7VoTA_large.jpg"
              alt="Kittens!"
              mx="auto"
              ignoreFallback
            />
          </Center>
        </Box>
      );
  }
};

export default observer(Question);
