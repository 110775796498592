import React from 'react';
import { Box, Heading } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { Room } from '../store/room';

interface HeaderProps {
  room: Room;
}

const Header: React.FC<HeaderProps> = ({ room }) => {
  if (!room.isLoaded) {
    return null;
  }

  return (
    <Box bg="orange.100" p={16} textAlign="center">
      <Heading color="orange.500" as="h1" size="2xl" fontWeight={900} my={0}>
        {room.data.name}
      </Heading>
    </Box>
  );
};

export default observer(Header);
