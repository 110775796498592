import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import PageNotFound from './PageNotFound';
import CreateRoom from './start/CreateRoom';
import Host from './host/Host';
import Participant from './participant/Participant';

const App: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.home} exact>
        <CreateRoom />
      </Route>
      <Route path={routes.host()} exact>
        <Host />
      </Route>
      <Route path={routes.participate()} exact>
        <Participant />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default App;
