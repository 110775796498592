import React from 'react';
import { Button, Center, Flex, Heading } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useCurrentRoom from './useCurrentRoom';
import Header from './Header';
import AddQuestion from './AddQuestion';

import routes from '../routes';
import ListQuestions from './ListQuestions';
import { QuestionType } from '../store/question';

const Host: React.FC = () => {
  const roomDocument = useCurrentRoom();
  const history = useHistory();
  const addQuestion = async (type: QuestionType) => {
    roomDocument.addQuestion(type);
  };

  const deleteRoom = async () => {
    roomDocument.delete();
    history.push(routes.home);
  };

  return (
    <>
      <Header room={roomDocument} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        py={8}
        bg="white"
        color="orange.500"
      >
        <Center>
          <Heading>Add Question</Heading>
        </Center>
        <Center>
          <AddQuestion onSelect={addQuestion} />
        </Center>
        {/* 
        <Center my={6}>
          <Responses questionType={roomDocument.data.questionType} />
        </Center> */}
        <Center>
          <Heading>Current Questions</Heading>
        </Center>
        <Center>
          <ListQuestions />
        </Center>
        <Center my={6}>
          <Button onClick={deleteRoom}>Delete Room</Button>
        </Center>
      </Flex>
    </>
  );
};

export default observer(Host);
