import { Center, Container, Divider, Flex, Heading } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import ParticipantList from './ParticipantList';
import { getUniqueAnswers } from '../helpers';
import { Question } from '../../store/question';

interface ParticipantBreakdownProps {
  question: Question;
}

const ParticipantBreakdown: React.FC<ParticipantBreakdownProps> = ({
  question,
}) => {
  const responseData = question.responses;
  if (!responseData) {
    return null;
  }
  const responses = responseData.docs.map((response) => {
    return { ...response.data };
  });
  const columnData = getUniqueAnswers(responses).map((answer) => {
    return {
      answer,
      responses: responses.filter((response) => response.value === answer),
    };
  });

  if (!columnData.length) {
    return null;
  }

  return (
    <Container>
      <Center flexDirection="column">
        <Heading as="h2" size="md" color="orange.500">
          Participant Breakdown
        </Heading>
        <Divider my={4} orientation="horizontal" />
      </Center>
      <Flex justifyContent="space-around">
        {columnData.map((item) => (
          <ParticipantList key={item.answer} {...item} />
        ))}
      </Flex>
    </Container>
  );
};

export default observer(ParticipantBreakdown);
