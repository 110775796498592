import { useCallback, useEffect, useMemo } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useAuthStore } from '../AppContextProvider';

interface NamePromptForm {
  name: string;
}

type UseNamePromptFormReturn<T> = {
  onSubmit: (data: T) => void;
  form: UseFormMethods<T>;
};

export default function useNamePromptForm(
  onSuccess: () => void
): UseNamePromptFormReturn<NamePromptForm> {
  const authStore = useAuthStore();

  const defaultValues: NamePromptForm = useMemo(() => {
    return {
      name: authStore.name ?? '',
    };
  }, [authStore.name]);

  const form = useForm<NamePromptForm>({
    defaultValues,
  });

  const { reset } = form;
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = useCallback(
    async (data: NamePromptForm) => {
      await authStore.setName(data.name);
      onSuccess();
    },
    [authStore, onSuccess]
  );

  return {
    onSubmit,
    form,
  };
}
