import React from 'react';
import { Box } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import useCurrentRoom from './useCurrentRoom';
import HostQuestion from './HostQuestion';

const ListQuestions: React.FC = () => {
  const room = useCurrentRoom();
  const { questions } = room;

  if (!questions || !questions.hasDocs) {
    return <Box>No Questions, add one</Box>;
  }

  const questionList = questions.docs.map((q) => (
    <HostQuestion key={q.id} question={q} />
  ));

  return <Box>{questionList}</Box>;
};

export default observer(ListQuestions);
