import bind from 'bind-decorator';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { computed, observable } from 'mobx';

export default class AuthStore {
  private auth;

  @observable private init = false;

  @observable currentUser?: firebase.User | null;

  @observable loading = false;

  @observable name?: string;

  @computed get isSignedIn(): boolean | null {
    if (!this.init) {
      return null; // We don't know yet
    }
    return !!this.currentUser && !this.currentUser.isAnonymous;
  }

  constructor() {
    this.auth = firebase.auth();
    this.currentUser = this.auth.currentUser;
    this.auth.onAuthStateChanged((user) => {
      this.init = true;
      this.currentUser = user;
      // User signed in, there is a display name
      if (user?.displayName) {
        this.name = user.displayName;
      }
      // User signed out.
      if (!user) {
        this.name = undefined;
      }
    });
  }

  @bind
  async signInWithGoogle(): Promise<void> {
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  @bind
  async setName(name: string): Promise<void> {
    this.loading = true;
    if (!this.currentUser) {
      await this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const credential = await this.auth.signInAnonymously();
      await credential.user?.updateProfile({ displayName: name });
    } else {
      await this.currentUser.updateProfile({
        displayName: name,
      });
    }
    this.name = name;
    this.loading = false;
  }

  @bind
  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async signInAnonymously(): Promise<firebase.auth.UserCredential> {
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const credential = await this.auth.signInAnonymously();
    return credential;
  }

  async getUserId(): Promise<string> {
    if (this.currentUser) {
      return this.currentUser.uid;
    }
    const credential = await this.signInAnonymously();
    if (!credential.user) {
      throw new Error('Unable to fetch or create the user');
    }
    return credential.user?.uid;
  }
}
