import React from 'react';
import { Heading } from '@chakra-ui/core';

const PageNotFound: React.FC = () => {
  return (
    <>
      <Heading as="h1" size="xl">
        404 Page Not Found
      </Heading>
    </>
  );
};

export default PageNotFound;
