import React from 'react';
import {
  Button,
  Container,
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';

export interface CreateRoomFormValues {
  name: string;
}

interface CreateRoomFormProps {
  defaultValues?: CreateRoomFormValues;
  onSubmit: (data: CreateRoomFormValues) => void;
}

const CreateRoomForm: React.FC<CreateRoomFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { register, handleSubmit, formState } = useForm<CreateRoomFormValues>({
    defaultValues,
  });

  const { isSubmitting } = formState;
  return (
    <>
      <Container as="form" my={16} onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <InputGroup size="lg">
            <Input
              type="name"
              name="name"
              id="name"
              ref={register}
              disabled={isSubmitting}
              placeholder="Room name"
            />
            <InputRightAddon px={0} border={0} overflow="hidden">
              <Button
                type="submit"
                size="lg"
                colorScheme="primary"
                borderRadius={0}
                isLoading={isSubmitting}
              >
                Create Room
              </Button>
            </InputRightAddon>
          </InputGroup>
        </FormControl>
      </Container>
    </>
  );
};

export default CreateRoomForm;
