import bind from 'bind-decorator';
import { firestore } from 'firebase';
import { Collection, Document, getFirebase, getFirestore } from 'firestorter';
import { computed, when } from 'mobx';
import { Response, ResponseData } from './response';

export const QUESTION_COLLECTION = 'questions';

export enum QuestionType {
  YesNo = 'YesNo',
  Range = 'Range',
  Generic = 'Generic',
}

export interface QuestionData {
  type: QuestionType;
  owner: string;
  created: Date;
  options?: Record<string, string>;
}

export class Question extends Document<QuestionData> {
  private _responses?: Collection<Response>;

  private _batch?: firestore.WriteBatch;

  get created(): Date {
    return this.data.created;
  }

  get type(): QuestionType {
    return this.data.type;
  }

  set type(type: QuestionType) {
    if (this.type !== type) {
      this.clear(type);
    }
  }

  get options(): Record<string, string> {
    return this.data.options || {};
  }

  set options(options: Record<string, string>) {
    this.update({
      options,
    });
  }

  @computed
  get responses(): Collection<Response> {
    if (!this._responses) {
      this._responses = new Collection(`${this.path}/responses`, {
        createDocument: (s, o) => new Response(s, o),
      });
    }
    return this._responses;
  }

  @bind
  async addResponse(response: ResponseData): Promise<ResponseData> {
    const current = this.currentResponse(response.owner);
    if (!current) {
      return this.responses.add(response);
    }
    current.value = response.value;
    return current;
  }

  @bind
  currentResponse(owner: string): Response | undefined {
    const result = computed(() => {
      return this.responses.docs.find((r) => r.data.owner === owner);
    });
    return result.get();
  }

  @bind
  async delete(): Promise<void> {
    await this.clear();
    return super.delete();
  }

  @bind
  async clear(type?: QuestionType): Promise<void> {
    // If the collection is bound for the first time, it might not be loaded. Make sure it's loaded first.
    await when(() => this.responses.isLoaded);
    this._batch = getFirestore().batch();
    this.update({
      type: type || getFirebase().firestore.FieldValue.delete(),
    });
    this.responses.docs.forEach((r) => {
      if (r.ref && this._batch) {
        this._batch.delete(r.ref);
      }
    });
    this._batch.commit();
    this._responses = undefined;
  }

  static fromId(
    parent: string | (() => string | undefined) | undefined,
    id: string
  ): Question {
    return new Question(`${parent}/${QUESTION_COLLECTION}/${id}`);
  }
}
