import React from 'react';
import { Box, CircularProgress, Heading } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import useCurrentRoom from '../host/useCurrentRoom';
import Header from './Header';
import Question from './Question';
import { useAuthStore } from '../AppContextProvider';
import Account from './Account';

const Participant: React.FC = () => {
  const room = useCurrentRoom();
  const hasName = !!useAuthStore().name;
  if (!room.isLoaded) {
    return <CircularProgress />;
  }
  return (
    <Box>
      <Header room={room} />
      <Account />
      {!room.hasData && (
        <Heading>Looks like this room does not exist anymore</Heading>
      )}
      {room.hasData && hasName && <Question />}
    </Box>
  );
};

export default observer(Participant);
