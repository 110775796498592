import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/core';
import customChakraTheme from './theme/customChakraTheme';
import App from './App';
import AppContextProvider from './AppContextProvider';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customChakraTheme} resetCSS>
      <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
