import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/core';

const Header: React.FC = () => {
  return (
    <Box bg="orange.100" py={16} w="100%" color="orange.500">
      <Center>
        <Heading color="orange.500" as="h1" size="2xl" fontWeight={900} my={0}>
          Quick Poll
        </Heading>
      </Center>
    </Box>
  );
};

export default Header;
