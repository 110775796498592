import React, { useCallback } from 'react';
import { Button } from '@chakra-ui/core';
import { QuestionType } from '../store/question';

interface QuestionButtonProps {
  type: QuestionType;
  onSelect: (type: QuestionType) => void;
  value?: QuestionType;
}

const QuestionButton: React.FC<QuestionButtonProps> = ({
  type,
  onSelect,
  value,
  children,
}) => {
  const handleClick = useCallback(() => onSelect(type), [onSelect, type]);

  return (
    <Button
      onClick={handleClick}
      variant={value === type ? 'outline' : 'solid'}
    >
      {children}
    </Button>
  );
};

export default QuestionButton;
