import { useParams } from 'react-router-dom';
import { useRoomStore } from '../AppContextProvider';
import { Room } from '../store/room';

export default function useCurrentRoom(): Room {
  const { roomId } = useParams<{ roomId: string }>();

  const store = useRoomStore();
  return store.setCurrentRoom(roomId);
}
